import { Creation, CreationBase, GalleryItemSource, PoNVoid } from '@/types'
import { retry } from '@/service/job.service'
import Video, { VideoApi, VideoProps } from '../video'
import { CSSProperties, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Interactions, { defaultControls, InteractionsProps } from '@/components/interactions'
import { useInView } from 'react-intersection-observer'
import { useBreakpoint } from '@/hooks/useBreakPoint'
import useAmplitude from '@/hooks/useAmplitude'
import {
  openNewTab,
  utcDate,
  utcDateTime,
  cls,
  isMobile,
  whisper,
  preventDefault,
  stopPropagation,
  calculateAspectRatio,
  getCreationDetailUrl,
} from '@/utils'
import dayjs from 'dayjs'
import { DEFAULT_AVATAR } from '@/constants'
import { Prompt } from '@/app/creations/_prompt'
import { useAtomValue, useSetAtom } from 'jotai'
import { allowedNSFWCreationIdsAtom, creationCacheAtom } from '@/atoms'
import useJobStatus from '@/hooks/useJobStatus'
import useCreation from '@/hooks/useCreation'
import IconClockAdd from '@haiper/icons-svg/icons/outline/clock-add.svg'
import IconFeatures from '@haiper/icons-svg/icons/outline/features.svg'
import IconHD from '@haiper/icons-svg/icons/outline/hd-2.svg'
import IconSD from '@haiper/icons-svg/icons/outline/sd-2.svg'
import { nanoid } from 'nanoid'
import useBrowser from '@/hooks/useBrowser'
import PendingJob from '@/components/job/pending'
import ErrorJob from '@/components/job/error'
import Avatar from '@/components/avatar'
import useCreationActions from '@/hooks/useCreationActions'
import { useRouter } from 'next/navigation'

export interface GalleryItemProps extends Pick<InteractionsProps, 'size'> {
  className?: string
  source: GalleryItemSource
  creation: Creation
  userId: string | null
  singleColumn?: boolean
  onClick?: (item: CreationBase) => PoNVoid
  onDelete?: (item: CreationBase) => PoNVoid
  onShow?: (item: CreationBase) => PoNVoid
  variant?: 'outline' | 'transparent'
  waterfall?: boolean
}

const GalleryItem = forwardRef<HTMLDivElement, GalleryItemProps>(
  (
    {
      className,
      creation,
      source,
      userId,
      size,
      waterfall,
      variant,
      singleColumn,
      onClick,
      onDelete,
      onShow,
    }: GalleryItemProps,
    ref,
  ) => {
    const [hovering, setHovering] = useState(false)
    const { track } = useAmplitude()
    const router = useRouter()

    const creationId = creation?.creation_id

    const { isBelowMd } = useBreakpoint('md')
    const isOwner = creation?.user_id && creation?.user_id === userId

    const isInCreations = source === 'creations'
    const isInCollections = source === 'collection'
    const showAuthorInfo = !isInCreations

    const [retrying, setRetrying] = useState(false)
    const [jobStatusRefreshKey, setJobStatusRefreshKey] = useState('')
    const shouldQueryJobStatus =
      // isOwner &&
      isInCreations && (!['succeed', 'failed'].includes(creation?.status ?? '') || Boolean(jobStatusRefreshKey))

    const { data: innerJobStatus, isValidating: jobStatusLoading } = useJobStatus(
      shouldQueryJobStatus ? creationId : undefined,
      jobStatusRefreshKey,
    )

    const { data: innerCreation } = useCreation(innerJobStatus?.status === 'succeed' ? creationId : undefined)
    const latestData: Creation = innerCreation?.data ?? creation
    const jobStatus = shouldQueryJobStatus ? innerJobStatus ?? null : null

    const hasOutput = !isInCreations || creation?.status === 'succeed' || jobStatus?.status === 'succeed'

    const hasError =
      !retrying && (jobStatus?.status === 'failed' || (!shouldQueryJobStatus && latestData?.status === 'failed'))

    // 720P
    const upscaled = latestData?.type === 'upscale'

    const isHD = latestData?.settings?.resolution && latestData?.settings?.resolution >= 720

    const IconResolution = isHD ? IconHD : IconSD
    const iconResolutionRemark = isHD ? '720p' : '540p'

    const actions = useCreationActions({
      source,
      creationId: creation?.creation_id ?? '',
      outputId: creation?.output_id ?? '',
      initialData: creation,
      url: creation?.video_url || '',
      onDelete: () => onDelete?.(creation),
    })

    // const isPublic = !!(latestData?.is_public)
    const setCreationCache = useSetAtom(creationCacheAtom)
    const videoApiRef = useRef<VideoApi>(null)

    const showMode = useMemo(() => {
      if (isMobile()) return 'mobile'
      if (!singleColumn) return 'grid'
      return 'single'
    }, [singleColumn])

    const interactionControls: InteractionsProps['controls'] = useMemo(() => {
      if (isInCreations) {
        return {
          ...defaultControls,
          showExtend: true,
          showUpscale: true,
          showRegenerate: true,
          showVaryPrompt: true,
          showRepaint: true,
          showDelete: true,
          showVisibility: true,
          showShare: 'fold',
          showCollection: true,
          showFavorite: false,
        }
      }
      if (isInCollections) {
        return {
          showVisibility: 'fold',
          showDelete: !!isOwner,
          showShare: isOwner ? 'fold' : true,
          showCollection: !!isOwner,
          showFavorite: !isOwner,
          showFavoriteCount: !isOwner,
          showDownload: !!isOwner,
        }
      }
      return {
        showFavorite: true,
        showDownload: false,
        showFavoriteCount: true,
        showComment: true,
        // showShare: true,
      }
    }, [isInCreations, isOwner, isInCollections])

    useEffect(() => {
      const data = latestData
      if (data) {
        setCreationCache((prev) => {
          return {
            ...prev,
            [data.creation_id]: data,
          }
        })
      }
    }, [latestData, setCreationCache])

    const handleClick = useCallback(
      (e?: any) => {
        e?.preventDefault()
        e?.stopPropagation()
        if (!latestData?.is_illegal && !isInCreations && !isInCollections) {
          // do nothing if illegal
          onClick?.(creation)
        }
      },
      [onClick, creation, latestData, isInCreations, isInCollections],
    )

    const footerRef = useRef<HTMLDivElement>(null)

    const handleFooterClick = useCallback(
      (e: any) => {
        preventDefault(e)
        stopPropagation(e)
        if (
          !isInCreations &&
          !isInCollections &&
          (e.target === footerRef.current || e.target?.parentElement?.parentElement === footerRef.current)
        ) {
          onClick?.(creation)
        }
      },
      [onClick, creation, isInCollections, isInCreations],
    )

    const { ref: contentRef, inView } = useInView({
      threshold: 0.75,
      triggerOnce: true,
      onChange(inView, entry) {
        if (inView) {
          onShow?.(creation)
        }
      },
    })

    const trackEventParams = useMemo(() => {
      return {
        creation_id: creation?.creation_id,
        source,
        is_author: isOwner || isInCreations,
        create_time: creation?.create_time ? utcDateTime(creation?.create_time) : null,
        // UTC + 0
        create_date: creation?.create_time ? utcDate(creation?.create_time) : null,
      }
    }, [source, isOwner, creation, isInCreations])

    const handleGotoAuthorProfile = useCallback(() => {
      if (!creation?.user_id) return

      track('click:creation:author', {
        ...trackEventParams,
        target_user_id: creation?.user_id,
      })

      // open in new tab
      const url = `/profile/${creation?.user_id}`
      openNewTab(url)
    }, [creation, track, trackEventParams])

    const handleGotoVideoDetail = useCallback(() => {
      const detailUrl = getCreationDetailUrl(creation)
      router.push(detailUrl)
    }, [creation, router])

    const hoverPlayEventParamsRef = useRef({
      hover_start_time: '',
      hover_end_time: '',
      hover_loop_times: 0,
      hover_duration: 0,
    })

    const handleHoverPlayStart = useCallback(() => {
      hoverPlayEventParamsRef.current.hover_start_time = utcDateTime()
      hoverPlayEventParamsRef.current.hover_end_time = ''
      hoverPlayEventParamsRef.current.hover_loop_times = 0
      hoverPlayEventParamsRef.current.hover_duration = 0
    }, [])

    const handleHoverPlayEnd = useCallback(() => {
      hoverPlayEventParamsRef.current.hover_end_time = utcDateTime()
      hoverPlayEventParamsRef.current.hover_loop_times = hoverPlayEventParamsRef.current.hover_loop_times + 1
      hoverPlayEventParamsRef.current.hover_duration = dayjs(hoverPlayEventParamsRef.current.hover_end_time).diff(
        dayjs(hoverPlayEventParamsRef.current.hover_start_time),
        'milliseconds',
      )
    }, [])

    const handleMouseEnter = useCallback(() => {
      setHovering(true)
    }, [])

    const handleMouseLeave = useCallback(() => {
      setHovering(false)
      if (hoverPlayEventParamsRef.current.hover_loop_times > 0) {
        hoverPlayEventParamsRef.current.hover_end_time = utcDateTime()
        hoverPlayEventParamsRef.current.hover_duration = dayjs(hoverPlayEventParamsRef.current.hover_end_time).diff(
          dayjs(hoverPlayEventParamsRef.current.hover_start_time),
          'milliseconds',
        )
        track('hover:creation:auto-play', {
          ...trackEventParams,
          ...hoverPlayEventParamsRef.current,
        })
      }
    }, [track, trackEventParams])

    const handleRetry = useCallback(
      async (e: any) => {
        e?.preventDefault?.()
        e?.stopPropagation?.()

        if (creationId) {
          await retry(creationId)
          setRetrying(true)
          setTimeout(() => {
            setJobStatusRefreshKey(nanoid())
            setRetrying(false)
          }, 10 * 1000)
        }
      },
      [creationId],
    )

    useEffect(() => {
      if (innerJobStatus?.status === 'succeed') {
        track('click:creation:generate-success', {
          creation_id: creationId,
        })
      }
    }, [innerJobStatus, track, creationId])
    const browser = useBrowser()

    const statusIconContainerClassname = cls(
      'group p-[6px] size-8 hover:w-max cursor-default text-icon-on-color rounded-md flex gap-1 items-center bg-[rgb(18,18,23)]/80',
    )
    const statusIconClassname = cls('size-5 cursor-default text-icon-on-color')
    const statusIconLabelClassname = cls('text-text-on-color hidden group-hover:block duration-75')

    const creationsControlsProps: VideoProps['controlsProps'] = useMemo(() => {
      return {
        hidePlayPause: true,
        hideProgress: true,
        hideResolutions: true,
        // hideVolume: true,
        className: 'pl-[22px]',
        onFullscreen: handleGotoVideoDetail,
      }
    }, [handleGotoVideoDetail])

    const coverAspectStyle: CSSProperties = useMemo(() => {
      if (!waterfall) {
        return {}
      }

      const { width, height } = creation?.spec ?? {}
      const aspectRatio = calculateAspectRatio(width, height)
      return {
        aspectRatio,
      }
    }, [creation?.spec, waterfall])

    const allowedNSFWCreationIds = useAtomValue(allowedNSFWCreationIdsAtom)

    const realShowNSFW = latestData?.is_nsfw && !allowedNSFWCreationIds.includes(creationId)

    const renderMain = () => {
      if (hasOutput) {
        return (
          <Video
            // blurBg
            playOnHover
            playsInline
            controls={isInCreations || isInCollections}
            hasAudioTrack={latestData?.spec?.has_audio}
            // controls
            controlsProps={creationsControlsProps}
            api={videoApiRef}
            className={cls(
              'rounded-[6px] border border-border',
              waterfall ? 'object-cover object-top h-full' : 'aspect-video',
            )}
            roundedClassName={cls('rounded-sm', singleColumn ? 'sm:rounded-xl' : 'sm:rounded-md')}
            containerClassName={cls(!waterfall && 'aspect-video')}
            maskClassName='border'
            playClassName='pointer-events-none'
            canPlay={hovering || isMobile()}
            autoPlay={hovering && !isBelowMd}
            src={latestData.stream_url || latestData.video_url || latestData.output_url || ''}
            nsfw={latestData.is_nsfw}
            illegal={!!latestData.is_illegal}
            poster={latestData.thumbnail_url}
            creationId={latestData.creation_id}
            preload={isBelowMd || !inView ? 'none' : 'auto'}
            onClick={handleClick as any}
            onHoverPlayStart={handleHoverPlayStart}
            onHoverPlayEnd={handleHoverPlayEnd}
          />
        )
      }
      if (hasError) {
        return <ErrorJob showMode={showMode} handleRetry={handleRetry} jobStatus={jobStatus} creation={latestData} />
      }
      return <PendingJob showMode={showMode} creation={latestData} jobStatus={jobStatus} />
    }

    const showResolution = !upscaled && latestData?.type !== 'template'

    return (
      <div
        ref={ref}
        key={creation.creation_id}
        className={cls(
          '@container pointer-events-auto flex flex-col justify-center items-center h-auto max-h-full rounded-lg border-2 border-b-4 border-solid border-border p-2 box-content',
          !waterfall && 'tablet:aspect-video',
          isInCreations || isInCollections
            ? 'cursor-default'
            : 'cursor-pointer hover:border-border-hover active:border-border-hover',
          size === 'sm' ? 'gap-2' : 'gap-3',
          // hovering ? 'z-[1]' : 'z-0',
          browser?.name !== 'safari' ? (hovering ? 'z-[1]' : 'z-0') : '',
          className,
        )}
        style={coverAspectStyle}
        data-testid={`creation-item-${creation.creation_id}`}
        data-spec-width={creation?.spec?.width}
        data-spec-height={creation?.spec?.height}
        aria-label='gallery-item'
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={(e) => {
          if (e.target === e.currentTarget) {
            handleClick()
          }
        }}
      >
        {(isInCreations || isInCollections) && (
          <Prompt {...creation} className='w-full' size={size} variant={variant} />
        )}
        <div
          ref={contentRef}
          className={cls(
            'select-none size-full max-w-full max-h-full relative flex justify-center items-center rounded-lg bg-surface-on-video/70 backdrop-blur-md',
            !waterfall && 'aspect-video',
            creation?.is_illegal ? 'cursor-default' : 'cursor-pointer',
          )}
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              handleClick()
            }
          }}
        >
          {renderMain()}
          {hasOutput && isInCreations && !latestData.is_illegal && !realShowNSFW ? (
            <div className='absolute inset-0 z-30 pointer-events-none' aria-label='status icons'>
              <div className='absolute top-2 left-2 bg-transparent flex flex-col gap-1 items-start justify-start text-sm z-30 pointer-events-auto'>
                {showResolution && (
                  <div className='group p-[6px] h-8 cursor-default text-icon-on-color rounded-md bg-[rgb(18,18,23)]/80 flex gap-1 items-center pointer-events-auto'>
                    <IconResolution className='size-5 cursor-default text-icon-on-color' />
                    <span className='text-text-on-color hidden group-hover:block duration-75'>
                      {iconResolutionRemark}
                    </span>
                  </div>
                )}
                {creation?.type === 'extend' ? (
                  <div className={statusIconContainerClassname}>
                    <IconClockAdd className={statusIconClassname} />
                    <span className={statusIconLabelClassname}>Extended</span>
                  </div>
                ) : null}
                {creation?.type === 'upscale' ? (
                  <div className={statusIconContainerClassname}>
                    <IconFeatures className={statusIconClassname} />
                    <span className={statusIconLabelClassname}>Upscaled</span>
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
        <div
          ref={footerRef}
          className={cls(
            'w-full flex justify-between text-icon items-center h-9 @container',
            isInCreations || isInCollections ? 'cursor-default' : 'cursor-pointer',
          )}
          aria-label='video-footer'
          onClick={handleFooterClick}
        >
          <div
            className={cls(
              '@sm:mr-1 @md:mr-5 cursor-pointer flex gap-2.5 items-center p-0.5 pr-1 hover:bg-surface-hover rounded-md w-0 flex-1 max-w-max',
              showAuthorInfo ? '' : 'hidden',
            )}
            aria-label='author info'
          >
            <Avatar src={creation?.avatar || DEFAULT_AVATAR} />
            <div
              className='text-body-sm whitespace-nowrap truncate'
              aria-label='author name'
              onClick={handleGotoAuthorProfile}
            >
              {creation?.username ?? ''}
            </div>
          </div>
          <Interactions
            actions={actions}
            size={size}
            useButtonGroup={isInCreations}
            source={source}
            disabled={creation?.is_illegal || !hasOutput}
            className={cls('min-w-max overflow-hidden w-auto md:h-10', showAuthorInfo ? '' : 'flex-1')}
            controls={interactionControls}
            singleColumn={singleColumn}
            itemClassName={cls(
              'text-icon disabled:bg-transparent bg-transparent hover:bg-surface-hover active:bg-surface-hover px-[6px]',
            )}
            iconClassName={cls('bg-transparent hover:bg-transparent active:bg-transparent')}
            onClick={handleFooterClick}
          />
        </div>
      </div>
    )
  },
)

GalleryItem.displayName = 'GalleryItem'

export default GalleryItem
