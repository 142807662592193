import Button, { ButtonProps } from '@/components/button'
import IconDiamond from '@/public/assets/diamond.svg'
import { prioritize } from '@/service/job.service'
import { cls, preventDefaultAndStopPropagation, whisper } from '@/utils'
import { useCallback } from 'react'

export interface UpgradeButtonProps extends ButtonProps {
  jobId: string
}

const fastGenPrice = 40

export default function FastGenButton({ className, onClick, jobId, ...props }: UpgradeButtonProps) {
  const handleClick = useCallback(
    async (e: any) => {
      preventDefaultAndStopPropagation(e)
      await prioritize(jobId)
      await onClick?.(e)
    },
    [onClick, jobId],
  )

  return (
    <Button
      variant='primary'
      className={cls('flex h-8 px-2 py-1 rounded-md', className)}
      {...props}
      onClick={handleClick}
    >
      <div className='flex items-center gap-1'>
        <span className='px-1'>Fast generation</span>
        <div className='bg-black-opacity-15 h-5 flex px-1 gap-1 items-center rounded-sm text-body-sm'>
          <IconDiamond className='size-4' />
          {fastGenPrice}
        </div>
      </div>
    </Button>
  )
}
